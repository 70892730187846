<template>
  <!-- Footer -->
  <footer class="page-footer font-small pt-4">
    <b-container class="text-left">
      <b-row>
        <b-col sm="12" md="1" class="col-custom"></b-col>
        <b-col col="4" sm="4" md="2">
          <div v-if="info && info.label1">
            <div v-if="localeTitle == 'en'">
              <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">
                {{ info.label1.enTitle }}
              </h5>
              <ul class="list-unstyled">
                <li
                  v-for="item in info.label1.labelButtons"
                  :key="item.enTitle"
                >
                  <a v-on:click="open(item.url)" href="javascript:void(0);">{{
                    item.enTitle
                  }}</a>
                </li>
              </ul>
            </div>
            <div v-if="localeTitle == 'cn'">
              <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">
                {{ info.label1.cnTitle }}
              </h5>
              <ul class="list-unstyled">
                <li
                  v-for="item in info.label1.labelButtons"
                  :key="item.cnTitle"
                >
                  <a v-on:click="open(item.url)" href="javascript:void(0);">{{
                    item.cnTitle
                  }}</a>
                </li>
              </ul>
            </div>
            <div v-if="localeTitle == 'hk'">
              <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">
                {{ info.label1.hkTitle }}
              </h5>
              <ul class="list-unstyled">
                <li
                  v-for="item in info.label1.labelButtons"
                  :key="item.hkTitle"
                >
                  <a v-on:click="open(item.url)" href="javascript:void(0);">{{
                    item.hkTitle
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
          <b-container v-else>
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
          </b-container>
        </b-col>
        <hr class="clearfix w-100 d-none d-md-none" />
        <b-col col="4" sm="4" md="2">
          <div v-if="info && info.label2">
            <div v-if="localeTitle == 'en'">
              <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">
                {{ info.label2.enTitle }}
              </h5>
              <ul class="list-unstyled">
                <li
                  v-for="item in info.label2.labelButtons"
                  :key="item.enTitle"
                >
                  <a v-on:click="open(item.url)" href="javascript:void(0);">{{
                    item.enTitle
                  }}</a>
                </li>
              </ul>
            </div>
            <div v-if="localeTitle == 'cn'">
              <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">
                {{ info.label2.cnTitle }}
              </h5>
              <ul class="list-unstyled">
                <li
                  v-for="item in info.label2.labelButtons"
                  :key="item.cnTitle"
                >
                  <a v-on:click="open(item.url)" href="javascript:void(0);">{{
                    item.cnTitle
                  }}</a>
                </li>
              </ul>
            </div>
            <div v-if="localeTitle == 'hk'">
              <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">
                {{ info.label2.hkTitle }}
              </h5>
              <ul class="list-unstyled">
                <li
                  v-for="item in info.label2.labelButtons"
                  :key="item.hkTitle"
                >
                  <a v-on:click="open(item.url)" href="javascript:void(0);">{{
                    item.hkTitle
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
          <b-container v-else>
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
          </b-container>
        </b-col>
        <hr class="clearfix w-100 d-none d-md-none" />
        <b-col col="4" sm="4" md="2" class="">
          <div v-if="info && info.label3">
            <div v-if="localeTitle == 'en'">
              <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">
                {{ info.label3.enTitle }}
              </h5>
              <ul class="list-unstyled">
                <li
                  v-for="item in info.label3.labelButtons"
                  :key="item.enTitle"
                >
                  <a v-on:click="open(item.url)" href="javascript:void(0);">{{
                    item.enTitle
                  }}</a>
                </li>
              </ul>
            </div>
            <div v-if="localeTitle == 'cn'">
              <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">
                {{ info.label3.cnTitle }}
              </h5>
              <ul class="list-unstyled">
                <li
                  v-for="item in info.label3.labelButtons"
                  :key="item.cnTitle"
                >
                  <a v-on:click="open(item.url)" href="javascript:void(0);">{{
                    item.cnTitle
                  }}</a>
                </li>
              </ul>
            </div>
            <div v-if="localeTitle == 'hk'">
              <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">
                {{ info.label3.hkTitle }}
              </h5>
              <ul class="list-unstyled">
                <li
                  v-for="item in info.label3.labelButtons"
                  :key="item.hkTitle"
                >
                  <a v-on:click="open(item.url)" href="javascript:void(0);">{{
                    item.hkTitle
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
          <b-container v-else>
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
          </b-container>
        </b-col>
        <b-col md="1" class="col-custom-border d-none d-md-block">
          <div class="hr-vertical"></div>
        </b-col>
        <hr class="clearfix w-100 d-none d-md-none" />
        <b-col md="3" class="col-ft-mid">
          <div v-if="info">
            <div v-if="localeTitle == 'en'">
              <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">
                {{ info.enTitle }}
              </h5>
            </div>
            <div v-if="localeTitle == 'cn'">
              <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">
                {{ info.cnTitle }}
              </h5>
            </div>
            <div v-if="localeTitle == 'hk'">
              <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">
                {{ info.hkTitle }}
              </h5>
            </div>
            <div v-if="info.phoneNumber" class="flex-center social-footer">
              <p>
                <a
                  class="social-ic call-footer-icon"
                  href="javascript:void(0);"
                >
                  <img src="@/assets/img/icon/icon-phone.png" alt="Image" />
                  {{ $t('Contact_us') }}
                  {{ info.phoneNumber }}</a
                >
              </p>
            </div>
            <div v-if="localeTitle == 'en'">
              <p>{{ info.enInformation }}</p>
            </div>
            <div v-if="localeTitle == 'cn'">
              <p>{{ info.cnInformation }}</p>
            </div>
            <div v-if="localeTitle == 'hk'">
              <p>{{ info.hkInformation }}</p>
            </div>

            <div class="mb-0 mb-md-5 flex-center social-footer">
              <a
                v-for="item in info.icons"
                :key="item.iconName"
                class="social-ic"
                :href="item.url"
              >
                <img
                  v-if="item.iconName === 'fa-weibo'"
                  src="@/assets/img/icon/icon-webo.png"
                  alt="Image"
                />
                <img
                  v-if="item.iconName === 'fa-facebook'"
                  src="@/assets/img/icon/icon-facebook.png"
                  alt="Image"
                />
                <img
                  v-if="item.iconName === 'fa-twitter'"
                  src="@/assets/img/icon/icon-twitter.png"
                  alt="Image"
                />
                <img
                  v-if="item.iconName === 'fa-weixin'"
                  src="@/assets/img/icon/icon-wechat.png"
                  alt="Image"
                />
                <img
                  v-if="item.iconName === 'fa-youtube'"
                  src="@/assets/img/icon/youtube.png"
                  alt="Image"
                />
                <img
                  v-if="item.iconName === 'fa-blogger'"
                  src="@/assets/img/icon/blogger.png"
                  alt="Image"
                />
                <img
                  v-if="item.iconName === 'fa-linkedin'"
                  src="@/assets/img/icon/linkedin.png"
                  alt="Image"
                />
                <img
                  v-if="item.iconName === 'fa-skype'"
                  src="@/assets/img/icon/skype.png"
                  alt="Image"
                />
                <img
                  v-if="item.iconName === 'fa-apple'"
                  src="@/assets/img/icon/apple.png"
                  alt="Image"
                />
                <img
                  v-if="item.iconName === 'fa-google-play'"
                  src="@/assets/img/icon/gooole.png"
                  alt="Image"
                />
                <img
                  v-if="item.iconName === 'fa-snapchat'"
                  src="@/assets/img/icon/snapchat.png"
                  alt="Image"
                />
                <img
                  v-if="item.iconName === 'fa-paypal'"
                  src="@/assets/img/icon/paypal.png"
                  alt="Image"
                />
                <img
                  v-if="item.iconName === 'fa-instagram'"
                  src="@/assets/img/icon/instagram.png"
                  alt="Image"
                />
              </a>
            </div>
          </div>
          <b-container v-else>
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
          </b-container>
        </b-col>
        <hr class="clearfix w-100 d-md-none" />
        <b-col md="1" class="col-custom"></b-col>
      </b-row>
    </b-container>
    <hr />
    <div class="footer-copyright text-center py-3">
      <b-container fluid v-if="info">
        {{ info.copyright }}
      </b-container>
      <b-container v-else>
        <b-skeleton></b-skeleton>
      </b-container>
    </div>
  </footer>
  <!-- Footer -->
</template>
<script>
export default {
  name: 'FooterPage',
  data() {
    return {
      info: null,
      loading: true,
      title: '',
      errored: false,
      infolength: null,
      slug: null,
      localeTitle: 'en',
      languageChange: '',
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLanguage;
    },
  },
  methods: {
    open(value) {
      window.open(value, '_self');
    },
    getLanChange() {
      if (this.languageChange == 'en') {
        this.localeTitle = 'en';
      } else if (this.languageChange == 'zh' || this.languageChange == 'cn') {
        this.localeTitle = 'cn';
      } else {
        this.localeTitle = 'hk';
      }
      // console.log(this.localeTitle, this.languageChange, ' this.localeTitle');
      const site = JSON.parse(localStorage.getItem('site'));
      this.info = site.footer;
    },
  },
  mounted() {
    this.languageChange = localStorage.getItem('lang_frontend');
    this.getLanChange();
  },
  watch: {
    lang(value) {
      this.languageChange = value;
      this.getLanChange();
    },
  },
};
</script>
<style type="text/css">
.page-footer {
  border-top: 1px solid #ede4e44d;
  background-color: #0e2b33;
}
.footer-copyright {
  background-color: #163e4a;
  color: #cccccc;
}
.footer-copyright a {
  color: #cccccc;
}
.page-footer h5 {
  color: #c6c6c6;
  font-size: 16px;
  line-height: 19px;
}
.page-footer li a,
.page-footer p {
  color: #c6c6c6;
  font-size: 14px;
  opacity: 0.6;
}
footer.mt-4 {
  margin-top: 0 !important;
}

footer .col-md-4 {
  flex: 0 0 40%;
  max-width: 40%;
}
footer .footer-copyright {
  padding: 2rem !important;
  color: #cccccc;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.5px;
  opacity: 0.9;
}
footer .hr-vertical {
  width: 0px;
  height: 112px;
  border: 1px solid #c6c6c6;
  opacity: 1;
  margin-top: 2rem;
}
.social-footer a.social-ic {
  color: #c6c6c6;
  font-size: 14px;
  /*opacity: 0.6;*/
}
.social-footer .social-ic img {
  color: #c6c6c6;
  font-size: 12px;
}
.social-footer .social-ic img {
  width: 45px;
  padding: 5px;
}
.social-footer p {
  margin-top: 1rem;
}
.social-footer .call-footer-icon.social-ic img {
  vertical-align: middle;
  width: 1.2rem;
}
.list-unstyled-last {
  margin-top: 2.3rem;
}

@media (min-width: 768px) {
  footer .col-custom {
    flex: 0 0 15%;
    max-width: 15%;
  }

  footer .col-md-2 {
    flex: 0 0 15%;
  }
}
</style>
